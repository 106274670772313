<template lang="pug">
div(class="container")
  div(class="card")
    header(class="card-header")
      p(class="card-header-title") Queues
    div(class="card-content")
      div(class="content")
        nx-table(
          urlFetch="queue/list"
          ref="nxTable"
          :backendPagination="false"
          :backendSorting="false"
        )
          template(#column)
            b-table-column(v-slot="props")
              b-button(
                icon-left="delete"
                type="is-small is-light"
                @click="deleteQueue(props.row)"
              )
    footer(class="card-footer")
</template>

<script>
import nxTable from '@nx-t/guzzi/vue/components/nxTable';

const axios = require('redaxios').default;

export default {
  components: {
    nxTable,
  },
  methods: {
    deleteQueue(row) {
      axios
        .post('queue/delete', { queue: row.name, id: row.jobId })
        .then((response) => {
          if (response.data && response.data.success) {
            this.$refs.nxTable.fetchData();
          } else if (response.data && response.data.errorMessage) {
            // eslint-disable-next-line no-alert
            alert(response.data.errorMessage);
          } else {
            // eslint-disable-next-line no-alert
            alert(response.data);
          }
        })
        .catch((error) => {
          // eslint-disable-next-line no-alert
          alert(error);
        });
    },
  },
};

</script>
