<template lang="pug">
div(class="container")
  div(class="card")
    header(class="card-header")
      p(class="card-header-title") Orders
    div(class="card-content")
      div(class="content")
        nx-table(
          urlFetch="order/list"
          ref="nxTable"
          :selectedColumns="[\
            'name',\
            'gguid',\
            'status',\
            'unique',\
            'createDate',\
            'selector',\
          ]"
        )
          template(#column-selector="row") {{ row.selector.name }}
    footer(class="card-footer")
</template>

<script>
import nxTable from '@nx-t/guzzi/vue/components/nxTable';

export default {
  components: {
    nxTable,
  },
};

</script>
