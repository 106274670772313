<template lang="pug">
div(class="container")
  form(@submit.prevent="importFile()")
    div(class="card")
      header(class="card-header")
        p(class="card-header-title") Import DB
      div(class="card-content")
        div(class="content")
          b-field(label="File")
            b-upload(v-model="file" class="file-label")
              span(class="file-cta")
                b-icon(class="file-icon" icon="upload")
                span(class="file-label") Import
              span(class="file-name" v-if="file") {{ file.name }}
          b-field(label="Merchant")
            b-select(
              v-model="merchantId"
              placeholder="Merchant"
              required
            )
              option(
                v-for="merchant in merchants"
                  :value="merchant._id"
                  :key="merchant._id") {{ merchant.displayname }}

      footer(class="card-footer")
        p(v-if="this.importError" class="error") {{importError}}
        b-button(
          label="Submit"
          icon-left="close"
          type="is-primary"
          @click="importFile()"
        )

  div(class="card")
    header(class="card-header")
      p(class="card-header-title") Delete all
    div(class="card-content")
      div(class="content")
    footer(class="card-footer")
      p(v-if="this.delError" class="error") {{delError}}
      b-button(
        label="delete All"
        icon-left="close"
        type="submit"
        @click="deleteAll()"
      )
</template>

<script>
const axios = require('redaxios').default;

export default {
  data() {
    return {
      merchants: '',
      loading: false,
      data: null,
      importError: null,
      delError: null,
      merchantId: null,
      file: null,
    };
  },
  created() {
    this.fetchOrganizations();
  },
  methods: {
    fetchOrganizations() {
      this.merchants = null;

      axios
        .get('merchant/list')
        .then((response) => {
          this.columns = [];
          const { data } = response;
          this.merchants = data;
        });
    },
    importFile() {
      this.importError = null;
      this.loading = true;

      const formData = new FormData();
      formData.append('file', new Blob([this.file]), this.file.name);
      formData.append('merchantId', this.merchantId);

      axios
        .post('/db/importDb', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((response) => {
          if (response.data && response.data.success) {
            this.data = response.data;
          } else if (response.data && response.data.errorMessage) {
            this.importError = response.data.errorMessage;
          } else {
            this.importError = response;
          }
        })
        .catch((error) => {
          this.importError = error.toString();
        })
        .then(() => {
          this.loading = false;
        });
    },
    deleteAll() {
      this.delError = null;
      this.loading = true;

      axios
        .get('db/deleteAll')
        .catch((error) => {
          this.delError = error.toString();
        })
        .then(() => {
          this.loading = false;
        });
    },
  },
};

</script>
