import Vue from 'vue';
import Router from 'vue-router';
import home from './vue/home';
import orderList from './vue/order/list';
import orderAdd from './vue/order/add';
import merchantList from './vue/merchant/list';
import organizationList from './vue/organization/list';
import selectorList from './vue/selector/list';
import tagList from './vue/tag/list';
import paymentList from './vue/payment/list';
import dbImport from './vue/db/import';
import statusList from './vue/status/list';
import queueList from './vue/queue/list';

Vue.use(Router);

export default new Router({
  mode: 'history',
  routes: [
    { path: '/', component: home },
    { path: '/order/', component: orderList },
    { path: '/addOrder/:merchantToken/:nr/:id', component: orderAdd },
    { path: '/merchant/', component: merchantList },
    { path: '/organization/', component: organizationList },
    { path: '/selector/', component: selectorList },
    { path: '/tag/', component: tagList },
    { path: '/payment/', component: paymentList },
    { path: '/db/', component: dbImport },
    { path: '/status/', component: statusList },
    { path: '/queue/', component: queueList },
  ],
});
