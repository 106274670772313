import { render, staticRenderFns } from "./list.vue?vue&type=template&id=2b4e6df1&scoped=true&lang=pug&"
import script from "./list.vue?vue&type=script&lang=js&"
export * from "./list.vue?vue&type=script&lang=js&"
import style0 from "./list.vue?vue&type=style&index=0&id=2b4e6df1&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.10.0_css-loader@6.7.3_pug@3.0.2_vue-template-compiler@2.7.13_webpack@5.75.0/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2b4e6df1",
  null
  
)

export default component.exports