<template lang="pug">
div(class="container")
  div(class="card")
    header(class="card-header")
      p(class="card-header-title") Add order
    div(class="card-content")
      div(v-if="loading" class="loading")
        p Loading . . .

      b-error(v-if="error" :error="error" class="error")
    footer(class="card-footer")
</template>

<script>
const axios = require('redaxios').default;
const bError = require('@nx-t/guzzi/vue/components/bError').default;

export default {
  components: {
    bError,
  },
  data() {
    return {
      loading: false,
      error: null,
    };
  },
  created() {
    this.merchantToken = this.$route.params.merchantToken;
    this.nr = this.$route.params.nr;
    this.id = this.$route.params.id;

    this.fetchData();
  },
  methods: {
    fetchData() {
      this.error = null;
      this.loading = true;

      axios
        .get('/order/add', { params: { merchantToken: this.merchantToken, nr: this.nr, id: this.id } })
        .then((response) => {
          if (response.data) {
            if (response.data.nr.toString() !== this.nr || response.data.id !== this.id) {
              this.error = 'unmatched response';
              return;
            }
            if (!response.data.success) {
              this.error = response.data.errorMessage;
            } else if (response.data.existingOrderUrl) {
              window.location.href = response.data.existingOrderUrl;
            } else {
              let params = {
                orgId: response.data.data.orgId,
                type: 'planC1',
                order: encodeURIComponent(JSON.stringify(response.data.data.order)),
                assets: encodeURIComponent(JSON.stringify(response.data.data.assets)),
                payments: encodeURIComponent(JSON.stringify(response.data.data.payments)),
              };

              params = new URLSearchParams(params);

              const myUrlWithParams = `${response.data.meinUrl}/prefill?${params}`;
              window.location.href = myUrlWithParams;
            }
          } else {
            this.error = 'no data';
          }
        })
        .catch((error) => {
          this.error = error.toString();
        })
        .then(() => {
          this.loading = false;
        });
    },
  },
};

</script>
