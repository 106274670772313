<template lang="pug">
div(class="container")
  div(class="card")
    header(class="card-header")
      p(class="card-header-title") Tags
    div(class="card-content")
      b-error(v-if="error" :error="error" class="error")
      div(class="content")
        nx-table(
          urlAdd="tag/add"
          urlEdit="tag/edit"
          urlFetch="tag/list"
          urlDelete="tag/delete"
          ref="nxTable"
          :selectedColumns="[\
            'name',\
            'selector',\
          ]"
          :checkable="true"
          @fillEdit="fill($event)"
        )
          template(#column-selector="row") {{ row.selector.name }}
          template(#form="props")
            div(class="modal-card" style="width: auto")
              header(class="modal-card-head")
                p(class="modal-card-title") {{ props.modeAdd ? 'Add tag' : 'Edit tag' }}
              section(class="modal-card-body")
                b-field(label="Selector")
                  b-select(
                    v-model="formData.selectorId"
                    placeholder="Selector"
                    required
                  )
                    option(
                      v-for="selector in selectors"
                      :value="selector._id"
                      :key="selector._id") {{ selector.name }}

                b-field(label="Name")
                  b-input(
                    type="string"
                    v-model="formData.name"
                    placeholder="Name"
                    required
                  )

              footer(class="modal-card-foot")
                b-button(
                  label="Close"
                  icon-left="close"
                  @click="$refs.nxTable.close()"
                )
                b-button(
                  label="Save"
                  icon-left="plus"
                  type="is-primary"
                  @click="$refs.nxTable.save(formData)"
                )
                b-message(v-if="props.error" title="error") {{ props.error }}

    footer(class="card-footer")
</template>

<script>
import nxTable from '@nx-t/guzzi/vue/components/nxTable';
import bError from '@nx-t/guzzi/vue/components/bError';

const axios = require('redaxios').default;

export default {
  components: {
    nxTable,
    bError,
  },
  data() {
    return {
      selectors: '',
      formData: {
        selectorID: null,
        name: '',
      },
      error: null,
    };
  },
  created() {
    this.fetchSelectors();
  },
  methods: {
    fetchSelectors() {
      this.selectors = null;

      axios
        .get('selector/list')
        .then((response) => {
          if (response.data && response.data.success) {
            this.columns = [];
            this.selectors = response.data.list;
          } else if (response.data && response.data.errorMessage) {
            this.error = response.data.errorMessage;
          } else {
            this.error = response.data;
          }
        })
        .catch((error) => {
          if (error.response && error.response.data) {
            this.error = error.response.data;
          } else {
            this.error = error.toString();
          }
        })
        .then(() => {
          this.loading = false;
        });
    },
    fill(editData) {
      this.formData = editData;
      this.formData.selectorId = editData.selector._id;
    },
  },
};

</script>
