<template lang="pug">
div(class="container")
  div(class="card")
    header(class="card-header")
      p(class="card-header-title") Status
    div(class="card-content")
      div(class="content")
        nx-table(
          urlFetch="status/list"
          ref="nxTable"
          :selectedColumns="[\
            'merchant',\
            'errorMessages',\
          ]"
        )
          template(#column)
            b-table-column(v-slot="props")
              b-button(
                icon-left="information"
                type="is-ghost"
                class="iconOnly"
                @click="info(props.row.jsonRights)"
              )

    footer(class="card-footer")
  b-modal(
    :active.sync="showForm"
    has-modal-card
    @close="close"
  )
    div(class="modal-card" style="width: auto")
      header(class="modal-card-head")
        p(class="modal-card-title") Permissions
      section(class="modal-card-body")
        b-field
          pre {{ modalData }}
  footer(class="modal-card-foot")

</template>

<script>
import nxTable from '@nx-t/guzzi/vue/components/nxTable';

export default {
  components: {
    nxTable,
  },
  data() {
    return {
      showForm: false,
      modalData: null,
    };
  },
  methods: {
    info(jsonRights) {
      this.showForm = true;
      this.modalData = jsonRights;
    },
    close() {
      this.showForm = false;
      this.modalData = null;
    },
  },
};

</script>

<style scoped>
  .iconOnly {
    height: 1em;
  }
</style>
