import { render, staticRenderFns } from "./home.vue?vue&type=template&id=618edfba&lang=pug&"
var script = {}


/* normalize component */
import normalizer from "!../../node_modules/.pnpm/vue-loader@15.10.0_css-loader@6.7.3_pug@3.0.2_vue-template-compiler@2.7.13_webpack@5.75.0/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports