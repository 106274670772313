import Vue from 'vue';
import Buefy from 'buefy';
import router from './router';

import 'buefy/dist/buefy.css';

import AppLayout from './vue/applayout';

Vue.use(Buefy);
Vue.config.productionTip = false;

/* eslint-disable no-new */
new Vue({
  el: '#app',
  render: (h) => h(AppLayout),
  router,
});
