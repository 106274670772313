<template lang="pug">
div#page
  div#header
    b-navbar(fixed-top shadow spaced)
      template(#brand)
        b-navbar-item(tag="router-link" :to="{ path: '/' }")
          img(
            src="/bezahlde.gif"
            alt=""
            srcset=""
          )
      template(#start v-if="showNavigation()")
        b-navbar-item(tag="router-link" :to="{ path: '/' }") Home
        b-navbar-dropdown(label="Config")
          b-navbar-item(tag="router-link" :to="{ path: '/merchant' }") Merchants
          b-navbar-item(tag="router-link" :to="{ path: '/organization' }") Organizations
          b-navbar-item(tag="router-link" :to="{ path: '/selector' }") Selectors
          b-navbar-item(tag="router-link" :to="{ path: '/tag' }") Tags
        b-navbar-dropdown(label="DB")
          b-navbar-item(tag="router-link" :to="{ path: '/db' }") DB
          b-navbar-item(tag="router-link" :to="{ path: '/order' }") Orders
          b-navbar-item(tag="router-link" :to="{ path: '/payment' }") Payments
        b-navbar-item(tag="router-link" :to="{ path: '/status' }") Status
        b-navbar-item(tag="router-link" :to="{ path: '/queue' }") Queue

      template(#end v-if="showNavigation()")
        b-navbar-item(tag="div")
          a(href="/nx.logout")
            b-button(
              label="Login"
              icon-left="login"
            )
  router-view(class="is-fluid")
</template>
<script>
export default {
  methods: {
    showNavigation() {
      return !this.$route.path.startsWith('/addOrder');
    },
  },
};
</script>
