<template lang="pug">
div(class="container")
  div(class="card")
    header(class="card-header")
      p(class="card-header-title") Organizations
    div(class="card-content")
      b-error(v-if="error" :error="error" class="error")
      div(class="content")
        nx-table(
          urlAdd="organization/add"
          urlEdit="organization/edit"
          urlFetch="organization/list"
          urlDelete="organization/delete"
          ref="nxTable"
          :selectedColumns="[\
            'name',\
            'orgId',\
            'gwgThresholdDate',\
            'merchant',\
          ]"
          :checkable="true"
          @fillEdit="fill($event)"
        )
          template(#column-merchant="row") {{ row.merchant.displayname }}
          template(#column-gwgThresholdDate="row") {{ getThresholdDate(row.gwgThresholdDate) }}
          template(#form="props")
            div(class="modal-card" style="width: auto")
              header(class="modal-card-head")
                p(class="modal-card-title") {{ props.modeAdd ? 'Add organization' : 'Edit organization' }}
              section(class="modal-card-body")
                b-field(label="Merchant")
                  b-select(
                    v-model="formData.merchantId"
                    placeholder="Merchant"
                    required
                  )
                    option(
                      v-for="merchant in merchants"
                      :value="merchant._id"
                      :key="merchant._id") {{ merchant.displayname }}

                b-field(label="Name")
                  b-input(
                    type="string"
                    v-model="formData.name"
                    placeholder="Name"
                    required
                  )

                b-field(label="orgId")
                  b-input(
                    type="string"
                    v-model="formData.orgId"
                    placeholder="orgId"
                    required
                  )

                b-field(label="Config")
                  vue-json-editor(
                    v-model="formData.config"
                    :expanded-on-start="true"
                  )

                b-field(label="gwgThresholdDate")
                  b-datepicker(
                    v-model="gwgThreshold"
                    position="is-top-left"
                    editable
                  )

              footer(class="modal-card-foot")
                b-button(
                  label="Close"
                  icon-left="close"
                  @click="$refs.nxTable.close()"
                )
                b-button(
                  label="Save"
                  icon-left="plus"
                  type="is-primary"
                  @click="$refs.nxTable.save(formData)"
                )
                b-message(v-if="props.error" title="error") {{ props.error }}

    footer(class="card-footer")
</template>

<script>
import moment from 'moment';
import vueJsonEditor from 'vue-json-editor';
import nxTable from '@nx-t/guzzi/vue/components/nxTable';
import bError from '@nx-t/guzzi/vue/components/bError';

const axios = require('redaxios').default;

export default {
  components: {
    nxTable,
    moment,
    bError,
    vueJsonEditor,
  },
  data() {
    return {
      merchants: [],
      formData: {
        merchantId: null,
        name: '',
        orgId: '',
        config: {},
        gwgThresholdDate: null,
      },
      error: null,
    };
  },
  computed: {
    gwgThreshold: {
      get() {
        return this.formData.gwgThresholdDate
          ? new Date(this.formData.gwgThresholdDate)
          : null;
      },
      set(value) {
        this.formData.gwgThresholdDate = value;
      },
    },
  },
  created() {
    this.fetchMerchants();
  },
  methods: {
    fetchMerchants() {
      this.merchants = null;

      axios
        .get('merchant/list')
        .then((response) => {
          if (response.data && response.data.success) {
            this.columns = [];
            this.merchants = response.data.list;
          } else if (response.data && response.data.errorMessage) {
            this.error = response.data.errorMessage;
          } else {
            this.error = response.data;
          }
        })
        .catch((error) => {
          if (error.response && error.response.data) {
            this.error = error.response.data;
          } else {
            this.error = error.toString();
          }
        })
        .then(() => {
          this.loading = false;
        });
    },
    fill(editData) {
      this.formData = editData;
      this.formData.merchantId = editData.merchant._id;
      if (!this.formData.config) {
        this.formData.config = {};
      }
    },

    getThresholdDate(date) {
      return date ? moment(date).format('DD.MM.YYYY') : undefined;
    },
  },
};

</script>
