<template lang="pug">
div(class="container")
  div(class="card")
    header(class="card-header")
      p(class="card-header-title") Selectors
    div(class="card-content")
      b-error(v-if="error" :error="error" class="error")
      div(class="content")
        nx-table(
          urlAdd="selector/add"
          urlEdit="selector/edit"
          urlFetch="selector/list"
          urlDelete="selector/delete"
          ref="nxTable"
          :selectedColumns="[\
            'name',\
            'regex',\
            'draftMode',\
            'organization',\
            'merchant',\
          ]"
          :checkable="true"
          @fillEdit="fill($event)"
        )
          template(#column-organization="row") {{ row.organization.name }}
          template(#column-merchant="row") {{ row.merchant.displayname }}
          template(#form="props")
            div(class="modal-card" style="width: auto")
              header(class="modal-card-head")
                p(class="modal-card-title") {{ props.modeAdd ? 'Add selector' : 'Edit selector' }}
              section(class="modal-card-body")
                b-field(label="Merchant")
                  b-select(
                    v-model="merchant"
                    placeholder="Merchant"
                    required
                  )
                    option(
                      v-for="merchant in merchants"
                      :value="merchant"
                      :key="merchant._id"
                    ) {{ merchant.displayname }}

                b-field(label="Organization")
                  b-select(
                    v-model="formData.organizationId"
                    placeholder="Organization"
                    required
                  )
                    template(v-if="merchant")
                      option(
                        v-for="organization in merchant.organizations"
                        :value="organization._id"
                        :key="organization._id"
                      ) {{ organization.name }}

                b-field(label="Name")
                  b-input(
                    type="string"
                    v-model="formData.name"
                    placeholder="Name"
                    required
                  )

                b-field(label="regex")
                  b-input(
                    type="string"
                    v-model="formData.regex"
                    placeholder="regex"
                    required
                  )

                b-field(label="draftMode")
                  b-checkbox(
                    v-model="formData.draftMode"
                  )
              footer(class="modal-card-foot")
                b-button(
                  label="Close"
                  icon-left="close"
                  @click="$refs.nxTable.close()"
                )
                b-button(
                  label="Save"
                  icon-left="plus"
                  type="is-primary"
                  @click="save()"
                )
                b-message(v-if="props.error" title="error") {{ props.error }}

    footer(class="card-footer")
</template>

<script>
import nxTable from '@nx-t/guzzi/vue/components/nxTable';
import bError from '@nx-t/guzzi/vue/components/bError';

const axios = require('redaxios').default;

export default {
  components: {
    nxTable,
    bError,
  },
  data() {
    return {
      merchants: [],
      merchant: null,
      formData: {
        name: '',
        regex: 'default',
        draftMode: false,
        organizationId: null,
        merchantId: null,
      },
      error: null,
    };
  },
  created() {
    this.fetchMerchants();
  },
  methods: {
    save() {
      this.formData.merchantId = this.merchant._id;
      this.$refs.nxTable.save(this.formData);
    },
    fetchMerchants() {
      this.merchants = null;

      axios
        .get('merchant/list')
        .then((response) => {
          if (response.data && response.data.success) {
            this.columns = [];
            this.merchants = response.data.list;
          } else if (response.data && response.data.errorMessage) {
            this.error = response.data.errorMessage;
          } else {
            this.error = response.data;
          }
        })
        .catch((error) => {
          if (error.response && error.response.data) {
            this.error = error.response.data;
          } else {
            this.error = error.toString();
          }
        })
        .then(() => {
          this.loading = false;
        });
    },
    fill(editData) {
      this.formData = editData;
      this.merchant = this.merchants.find((m) => m._id === editData.merchant._id);
      this.formData.organizationId = editData.organization._id;
    },
  },
};

</script>
