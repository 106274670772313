<template lang="pug">
div(class="container")
  div(class="card")
    header(class="card-header")
      p(class="card-header-title") Payments
    div(class="card-content")
      div(class="content")
        nx-table(
          urlFetch="payment/list"
          ref="nxTable"
        )
    footer(class="card-footer")
</template>

<script>
import nxTable from '@nx-t/guzzi/vue/components/nxTable';

export default {
  components: {
    nxTable,
  },
};

</script>
